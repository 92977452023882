import axios from '@/utils/request'

// 机构列表
export function organizationsLists(params) {
  // params.loading = true
  return axios({
    url: `/manager/account/organizations`,
    method: 'get',
		params
  })
}
// 新增机构
export function addOrganizations(data) {
  return axios({
    url: `/manager/account/organizations`,
    method: 'post',
		data
  })
}
// 机构详情
export function detailOrganizations(id) {
  return axios({
    url: `/manager/account/organizations/${id}`,
    method: 'get'
  })
}
// 机构详情修改
export function setOrganizations(id, data) {
  return axios({
    url: `/manager/account/organizations/${id}`,
    method: 'put',
		data
  })
}
// 新增/编辑支付产品通道
export function setPaychannels(id, data) {
  return axios({
    url: `/manager/account/organizations/${id}/paychannels/${data.payChannelId}`,
    method: 'post',
		data
  })
}
// 删除支付产品通道
export function delPaychannels(id, payChannelId) {
  return axios({
    url: `/manager/account/organizations/${id}/paychannels/${payChannelId}`,
    method: 'delete'
  })
}
// 新增/编辑代付产品通道
export function setAgnpaychannels(id, data) {
  return axios({
    url: `/manager/account/organizations/${id}/agnpaychannels/${data.agnpayChannelId}`,
    method: 'post',
		data
  })
}
// 删除代付产品通道
export function delAgnpaychannels(id, agnpayChannelId) {
  return axios({
    url: `/manager/account/organizations/${id}/agnpaychannels/${agnpayChannelId}`,
    method: 'delete'
  })
}
// 新增/编辑分账产品通道
export function setAllocchannels(id, data) {
  return axios({
    url: `/manager/account/organizations/${id}/allocchannels/${data.allocChannelId}`,
    method: 'post',
		data
  })
}
// 删除分账产品通道
export function delAllocchannels(id, allocChannelId) {
  return axios({
    url: `/manager/account/organizations/${id}/allocchannels/${allocChannelId}`,
    method: 'delete'
  })
}
// 启用/关闭机构
export function setStatus(data) {
  return axios({
    url: `/manager/account/organizations/${data.orgId}/status`,
    method: 'put',
		data
  })
}
