<template>
  <div id="mian">
    <div class="header">
      <span id="fzcolor">基本信息</span>
      <span @click="toUrl('edit_organization_jbxx', '产品信息')">产品信息</span>
    </div>
    <div v-auth="'ACCOUNT:ORG:ORGS:BASE'">
      <div class="desc">
        <h5 class="jsxx float_left">基本信息</h5>
        <el-button
          v-auth="'ACCOUNT:ORG:ORGS:BASE/EDIT'"
          @click="toUrl('edit_organization_jbxx', '基本信息')"
          class="editBt"
          type="primary"
          >编辑</el-button
        >
      </div>
      <ul class="listBox">
          <li>
            <span>机构名称</span>
            <i class="hidden1">
              <el-tooltip
                class="item"
                effect="light"
                :content="detail.orgName"
                placement="top"
              >
                <span>{{ detail.orgName }}</span>
              </el-tooltip>
            </i>
          </li>
          <li>
            <span>机构编号</span>
            <i class="hidden1">{{ detail.orgNo }}</i>
          </li>
          <li>
            <span>联系人</span>
            <i class="hidden1">{{ detail.contactName }}</i>
          </li>
          <li>
            <span>手机号码</span>
            <i class="hidden1">{{ detail.phoneNumber }}</i>
          </li>
          <li>
            <span>邮箱</span>
            <i class="hidden1">{{ detail.contactEmail }}</i>
          </li>
          <li>
            <span>客服电话</span>
            <i class="hidden1">{{ detail.servicePhone }}</i>
          </li>
          <li>
            <span>网址</span>
            <i class="hidden1">
              <el-tooltip
                class="item"
                effect="light"
                :content="detail.site"
                placement="top"
              >
                <span>{{ detail.site }}</span>
              </el-tooltip>
            </i>
          </li>
          <li>
            <span>联系地址</span>
            <i class="hidden1">
              <el-tooltip
                class="item"
                effect="light"
                :content="detail.contactAddress"
                placement="top"
              >
                <span>{{ detail.contactAddress }}</span>
              </el-tooltip>
            </i>
          </li>
          <li>
            <span>备注</span>
            <i class="hidden1">
              <el-tooltip
                class="item"
                effect="light"
                :content="detail.remark"
                placement="top"
              >
                <span>{{ detail.remark }}</span>
              </el-tooltip>
            </i>
          </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { detailOrganizations } from "@/api/user/organizations.js";
export default {
  data() {
    return {
      detail: "",
    };
  },
  created() {
    this.getDetail();
  },
  methods: {
    //获取详情
    getDetail() {
      detailOrganizations(this.$route.query.id).then((res) => {
        if (res) {
          this.detail = res.resultData.orgDetail;
        }
      });
    },
    // 跳转页面
    toUrl(name, active) {
      this.$router.replace({
        name: name,
        query: {
          pageType: "edit",
          id: this.$route.query.id,
          active,
        },
      });
      // this.$router.push({
      // 	name: name,
      // 	query: {
      // 		pageType: 'edit',
      // 		id: this.$route.query.id,
      // 		active
      // 	}
      // })
    },
  },
};
</script>
<style scoped>
@import "../../../../assets/css/desc.css";
@import "../../../../assets/css/detail.css";
.tab1{
  min-height: auto !important;
}
</style>
